import request from "@/utils/request";

const prefix = '/gallery'
// export const getImglist = (
//   {
//     perPage,
//     is_all
//   },
//   callback
// ) => {
//   const data = {
//     perPage,
//     is_all
//   }
//     return request(`${prefix}/imagesList`, {params: data, method: 'get'}, callback)
// }
export const getImglist = (
    {
        perPage,
        is_all
    },
    callback
) => {
    const data = {
        perPage,
        is_all
    }
    return request('/gallery/getImagesPaginate', {params: data, method: 'get'}, callback)
}
// 获取我的图库列表
export const getImagesList = (
    params,
    callback
) => {

    return request('/gallery/getImagesPaginate', {params, method: 'get'}, callback)
}


// 获取图册列表
// export const getPhotolist = (
//
//   callback
// ) => {
//     return request(`${prefix}/albumList`, {params: {}, method: 'get'}, callback)
// }

export const getPhotolist = (
    callback
) => {
    return request('/gallery/getAlbumList', {params: {}, method: 'get'}, callback)
}


// 获取分类列表
export const systemClassify = (
    callback
) => {
    return request(`${prefix}/systemClassify`, {params: {}, method: 'get'}, callback)
}
// 编辑图册
export const puteditAlbum = (
    {
        albumId,
        name,
        brief
    },
    callback
) => {
    const data = {
        albumId,
        name,
        brief
    }
    return request(`${prefix}/editAlbum`, {params: data, method: 'put'}, callback)
}
// 添加图册
export const postaddAlbum = (
    {
        name,
        brief
    },
    callback
) => {
    const data = {
        name,
        brief
    }
    return request(`${prefix}/addAlbum`, {params: data, method: 'post'}, callback)
}
// 删除图册
export const deleteAlbum = (
    {
        albumId
    },
    callback
) => {
    const data = {
        albumId
    }
    return request(`${prefix}/deleteAlbum`, {params: data, method: 'delete'}, callback)
}

//更改图片名称
// export const upDadeImgName = (
//   params,
//   callback
// ) => {
//
//     return request(`${prefix}/upDadeImgName`, {params, method: 'put'}, callback)
// }
export const upDadeImgName = (
    params,
    callback
) => {

    return request('/gallery/editImgName', {params, method: 'put'}, callback)
}

//删除图片
export const deleteImages = (
    params,
    callback
) => {

    return request(`${prefix}/deleteImages`, {params, method: 'delete'}, callback)
}
// 添加到图册 
export const addToAlbum = (
    params,
    callback
) => {

    return request(`${prefix}/addToAlbum`, {params, method: 'put'}, callback)
}
// 获取相册列表--分页

// export const albumPaging = (
//   params,
//   callback
// ) => {
//
//     return request(`${prefix}/albumPaging`, {params, method: 'get'}, callback)
// }

export const albumPaging = (
    params,
    callback
) => {

    return request('/gallery/getAlbumPaging', {params, method: 'get'}, callback)
}


//验证图片md5

export const verifyImageMd5 = (
    params,
    callback
) => {

    return request(`${prefix}/verifyImageMd5`, {params, method: 'get'}, callback)
}

// 保存图片
export const saveImage = (
    params,
    callback
) => {

    return request(`${prefix}/saveImage`, {params, method: 'post'}, callback)
}
// 获取OssStsConfig
// export const ossStsConfig = (
//   callback
// ) => {
//     return request(`${prefix}/ossStsConfig`, { method: 'get'}, callback)
// }
export const ossStsConfig = (
    callback
) => {
    return request('/gallery/getOssStsConfig', {method: 'get'}, callback)
}


//判断图片是否有效gallery/getImgFormatInfo

export const getImgFormatInfo = (
    {
        imgUrl
    },
    callback
) => {
    const params = {
        imgUrl
    }
    return request(`${prefix}/getImgFormatInfo`, {params, method: 'get'}, callback)
}

